/**
 * This is a polyfill for Error.cause
 * It can be removed once our all browsers support it natively -
 * see https://caniuse.com/mdn-javascript_builtins_error_cause
 */
import 'core-js/stable/error'

import 'core-js/stable/array/at'

import './deprecation-workflow'

import Application from '@ember/application'
import Resolver from 'ember-resolver'
import loadInitializers from 'ember-load-initializers'
import { startLog, checkIgnore } from '@blakeelearning/log'
import { setupDatadogRum } from 'fast-phonics-client/utils/datadog-rum'
import config from 'fast-phonics-client/config/environment'

export default class App extends Application {
  modulePrefix = config.modulePrefix

  podModulePrefix = config.podModulePrefix

  Resolver = Resolver
}

startLog({
  rollbar: {
    ...config.rollbar,
    ignoredMessages: [
      // ignore ScriptBlockedError
      // ignoredMessages matchers are actually regular expressions
      // https://docs.rollbar.com/docs/javascript#ignoring-specific-exception-messages
      "^Script '.*' blocked$",
    ],
    checkIgnore,
  },
})

setupDatadogRum()

loadInitializers(App, config.modulePrefix)
