import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.shouldDisplay}}\n  <div class=\"{{style-namespace 'debug-mode/toggle-debug'}}\">\n    <button\n      data-test-toggle-debug-button\n      class=\"toggle-button\"\n      type=\"button\"\n      {{on \"click\" this.toggleDebug}}\n    >\n      {{#if this.debugModeEnabled}}\n        Disable QA Debug Mode\n      {{else}}\n        Enable QA Debug Mode\n      {{/if}}\n    </button>\n  </div>\n{{/if}}", {"contents":"{{#if this.shouldDisplay}}\n  <div class=\"{{style-namespace 'debug-mode/toggle-debug'}}\">\n    <button\n      data-test-toggle-debug-button\n      class=\"toggle-button\"\n      type=\"button\"\n      {{on \"click\" this.toggleDebug}}\n    >\n      {{#if this.debugModeEnabled}}\n        Disable QA Debug Mode\n      {{else}}\n        Enable QA Debug Mode\n      {{/if}}\n    </button>\n  </div>\n{{/if}}","moduleName":"fast-phonics-client/components/debug-mode/toggle-debug.hbs","parseOptions":{"srcName":"fast-phonics-client/components/debug-mode/toggle-debug.hbs"}});
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Component from '@glimmer/component'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'

export default class DebugModeToggleDebug extends Component {
  @service declare debugMode: DebugModeService

  get shouldDisplay(): boolean {
    return this.debugMode.debugAllowed
  }

  get debugModeEnabled(): boolean {
    return this.debugMode.enabled
  }

  @action toggleDebug(): void {
    const { debugMode } = this

    if (debugMode.enabled) {
      debugMode.disable()
    } else {
      debugMode.enable()
    }
  }
}
