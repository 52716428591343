/* eslint-disable ember/no-runloop */
import Service, { inject as service } from '@ember/service'
import { later } from '@ember/runloop'
import { tracked } from '@glimmer/tracking'
import config from 'fast-phonics-client/config/environment'
import { getLastNavigationType } from 'fast-phonics-client/utils/navigation'
import type RouterService from '@ember/routing/router-service'

/**
 * A service for tracking the loading state of the app, so we can display loading UI
 *
 * This service makes 2 important assumptions:
 * 1. when this service is initialised, we're within the initial page loading cycle
 * 2. other parts of the app are responsible for informing this service when they
 *    start and finish loading
 *
 * Other code will call `start(fn, preDelay)` and `finish(postDelay)` according to
 * their needs, with `preDelay` and `postDelay` being globally configurable.
 * These functions give loading UI a chance to fade in before commencing
 * loading operations, usually Ember route transitions. Similarly, the loading
 * UI has a chance to fade out smoothly once the loading has completed.
 *
 * After initial page load, this service also removes any static loading screen
 * HTML rendered by the server, so that Ember components can assume control of
 * loading UI once the Ember app has booted for the first time.
 *
 * Additionally, the duration of the static loading screen fade out animation
 * can be set to 0 within the app's config, to avoid waiting for animations
 * during testing.
 */

export default class LoadingUiService extends Service {
  @service declare router: RouterService

  /**
   * the current loading state of the app, according to app components that want to display loading UI
   */
  @tracked isLoading = true

  staticLoadingElements = document.getElementsByTagName('loading-screen-static')

  /**
   * Duration of the fade transition in milliseconds
   */
  get fadeDuration() {
    return config.APP.loadingUi.fadeDuration ?? 0
  }

  /**
   * Duration of the pre delay in milliseconds
   */
  get _preDelay() {
    return config.APP.loadingUi.preDelay ?? 0
  }

  /**
   * Duration of the post delay in milliseconds
   */
  get _postDelay() {
    return config.APP.loadingUi.postDelay ?? 0
  }

  constructor(owner: Record<string, unknown>) {
    super(owner)
    window.addEventListener('pageshow', this._onPageShow)
  }

  override willDestroy(): void {
    window.removeEventListener('pageshow', this._onPageShow)
    super.willDestroy()
  }

  /**
   * Starts a loading UI cycle, running the provided callback after an optional delay
   *
   * This function will schedule to callback to run after the delay, using
   * Ember's runloop to apply the delay.
   *
   *  @example
   * loadingUi.start(() => {
   *   this.router.transitionTo('my-slow-loading-route')
   * }, 1000)
   */
  start(fn?: () => void, preDelay?: number): void {
    preDelay = preDelay ?? this._preDelay
    this.isLoading = true
    if (fn && preDelay > 0) {
      later(fn, preDelay)
    } else if (fn) {
      fn()
    }
  }

  /**
   * Finishes a loading UI cycle, optionally after a delay
   *
   * After initial page load, this function will remove any server-rendered
   * loading screen elements from the DOM.
   */
  finish(postDelay?: number): void {
    postDelay = postDelay ?? this._postDelay

    if (postDelay > 0) {
      later(() => {
        this._finishLoading()
      }, postDelay)
    } else {
      this._finishLoading()
    }
  }

  _finishLoading(): void {
    this.isLoading = false

    for (const staticLoadingElement of this.staticLoadingElements) {
      if (staticLoadingElement instanceof HTMLElement) {
        this._removeStaticLoadingScreen(staticLoadingElement)
      }
    }
  }

  /**
   * Removes the static loading element, if it exists
   */
  _removeStaticLoadingScreen(staticLoadingElement: HTMLElement): void {
    staticLoadingElement.addEventListener(
      'transitionend',
      () => {
        staticLoadingElement.remove()
      },
      { once: true },
    )
    staticLoadingElement.style.setProperty(
      'transition',
      `opacity ${this.fadeDuration.toFixed()}ms`,
    )
    staticLoadingElement.style.setProperty('opacity', '0')
  }

  _onPageShow = (pageTransitionEvent: PageTransitionEvent) => {
    // If the page was loaded from Safari's PageCache or
    // Firefox's Back/Forward Cache, then our interactives' readyForUserInput
    // won't fire, as the interactive will be revived in the same state
    // as when the user was previously using it.
    // This can occur when using the browser back/forward buttons, or their
    // equivalent Javascript APIs.
    // In these circumstances, we assume the loading screen can be removed.
    const didLoadFromCache = pageTransitionEvent.persisted
    if (didLoadFromCache && getLastNavigationType() === 'back_forward') {
      this.finish()
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'loading-ui': LoadingUiService
  }
}
