import type Model from '@ember-data/model'
import type { Snapshot } from '@ember-data/store'

/**
 * Implements our own version of the deprecated `toJSON` method for ember-data Models
 * https://deprecations.emberjs.com/ember-data/v3.x/#toc_record-toJSON
 *
 * This method preserves the camelCase of the model, which is expected by Jester activities
 *
 * @param record an ember-data Model
 * @param options options for the serializer
 * @returns JSON
 */
export default function toJSON(
  record: Model,
  options = {},
): Record<string, unknown> {
  const serializer = record.store.serializerFor('jester')
  // @ts-expect-error __createSnapshot() is private
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const snapshot = record._createSnapshot() as Snapshot
  return serializer.serialize(snapshot, options)
}
