import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FullViewport>\n  <div class={{style-namespace \"debug-mode/intro-video\"}} ...attributes>\n    <div\n      class=\"flex flex-col justify-center w-full h-screen intro-video-container\"\n    >\n      <div class=\"complete-video\">\n        <button\n          class=\"mx-2 w-1/4\"\n          data-test-go-back\n          type=\"button\"\n          {{on \"click\" this.goBack}}\n        >\n          Go Back\n        </button>\n        <button\n          class=\"mx-2 w-1/4\"\n          data-test-complete-video\n          type=\"button\"\n          {{on \"click\" this.completeVideo}}\n        >\n          Complete Video\n        </button>\n      </div>\n    </div>\n  </div>\n</FullViewport>", {"contents":"<FullViewport>\n  <div class={{style-namespace \"debug-mode/intro-video\"}} ...attributes>\n    <div\n      class=\"flex flex-col justify-center w-full h-screen intro-video-container\"\n    >\n      <div class=\"complete-video\">\n        <button\n          class=\"mx-2 w-1/4\"\n          data-test-go-back\n          type=\"button\"\n          {{on \"click\" this.goBack}}\n        >\n          Go Back\n        </button>\n        <button\n          class=\"mx-2 w-1/4\"\n          data-test-complete-video\n          type=\"button\"\n          {{on \"click\" this.completeVideo}}\n        >\n          Complete Video\n        </button>\n      </div>\n    </div>\n  </div>\n</FullViewport>","moduleName":"fast-phonics-client/components/debug-mode/intro-video.hbs","parseOptions":{"srcName":"fast-phonics-client/components/debug-mode/intro-video.hbs"}});
import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import type { VideoContentActions } from '@blakeelearning/content-specs-fast-phonics'

interface DebugModeIntroVideoComponentArgs {
  contentActions: VideoContentActions
}

export default class DebugModeIntroVideoComponent extends Component<DebugModeIntroVideoComponentArgs> {
  @service declare loadingUi: { finish: () => void } // FIXME: Once the loadingUI is converted to TS

  constructor(owner: unknown, args: DebugModeIntroVideoComponentArgs) {
    super(owner, args)
    this.loadingUi.finish()
  }

  @action completeVideo(): void {
    this.args.contentActions.completeVideo()
  }

  @action goBack(): void {
    this.args.contentActions.goBack()
  }
}
