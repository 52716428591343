import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<loading-screen\n  class=\"{{style-namespace 'loading-screen'}}\n    {{if this.loadingUi.isLoading 'fade-in' 'fade-out'}}\"\n  {{! template-lint-disable no-inline-styles style-concatenation }}\n  style=\"{{this.fadeDurationStyle}}\"\n>\n  <img id=\"loader\" src=\"/assets/svgs/loader.svg\" alt=\"Loading Fast Phonics\" />\n</loading-screen>", {"contents":"<loading-screen\n  class=\"{{style-namespace 'loading-screen'}}\n    {{if this.loadingUi.isLoading 'fade-in' 'fade-out'}}\"\n  {{! template-lint-disable no-inline-styles style-concatenation }}\n  style=\"{{this.fadeDurationStyle}}\"\n>\n  <img id=\"loader\" src=\"/assets/svgs/loader.svg\" alt=\"Loading Fast Phonics\" />\n</loading-screen>","moduleName":"fast-phonics-client/components/loading-screen.hbs","parseOptions":{"srcName":"fast-phonics-client/components/loading-screen.hbs"}});
import { inject as service } from '@ember/service'
import Component from '@glimmer/component'
import { htmlSafe } from '@ember/template'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'

/**
 * Display a full-page loading screen during app loading
 *
 * This component works in concert with the loading-ui service.
 *
 * It assumes the server-rendered HTML serving the Ember app will have
 * equivalent HTML and CSS for showing a loading screen during initial page
 * load. As such, this component will not be visible when the loading-ui
 * service indicates initial page load is not yet completed.
 *
 * Otherwise, the component will toggle visibility based on the loading-ui
 * service's loading state.
 *
 * The duration of the static loading screen fade out animation can be set to
 * 0 within the app's config, to avoid waiting for animations during testing.
 */
export default class LoadingScreen extends Component {
  @service declare loadingUi: LoadingUiService

  /**
   * CSS custom property to provide the fade duration for CSS transitions
   */
  get fadeDurationStyle() {
    return htmlSafe(
      `--loading-screen-fade-duration: ${this.loadingUi.fadeDuration.toFixed()}ms`,
    )
  }
}
